import Vue from 'vue';

const cache = {}; // In-memory cache per language

Vue.prototype.$cacheTypes = async (language) => {
    const now = Date.now();
    const cached = cache[language];

    // Use cache if valid
    if (cached && (now - cached.timestamp < 10 * 60 * 1000)) {
        return cached.data;
    }

    try {
        const res = await fetch(
            process.env.apiEndpoint + 'claims/type/all/' + language
        );
        const json = await res.json();

        cache[language] = {
            timestamp: now,
            data: json.response
        };

        return json.response;
    } catch (err) {
        console.error(`[cacheTypes] Failed to fetch for ${language}:`, err);
        return []; // Fallback to prevent crash
    }
};
