
import axios from '../plugins/axios';

export default {
  data() {
    return {
      toasts: [],
      toastHandler: null,
      reviews: {
        count: 0,
        average: 0
      }
    }
  },
  async fetch() {
    let data = await this.$content({path: '/feedbacks'}).fetch();
    if (data && data[0].average) {
      this.reviews = data[0];
    }
    this.setDefault();
  },
  beforeDestroy() {
    if (this.toastHandler) {
      this.$globalEvent.$off('toast', this.toastHandler);
    }
  },
  created() {
    this.setDefault();

    this.toastHandler = (message) => {
      message.visible = true;
      if (typeof message.timeout === 'undefined') {
        message.timeout = 8000;
      }

      let key = this.toasts.length;
      this.toasts.push(message);

      setTimeout(() => {
        if (this.toasts[key]) {
          this.toasts[key].visible = false;
        }
      }, this.toasts[key].timeout);
    };

    this.$globalEvent.$on('toast', this.toastHandler);

    // Affiliate
    if (process.client) {
      if (typeof this.$route.query !== 'undefined' && typeof this.$route.query.affID !== 'undefined') {
        this.$cookie.set('affID', this.$route.query.affID, 30);
        this.$api.affiliate.click({
          link: this.$route.query.affID,
          referrer: window.document.referrer
        });
      }

      // document.referrer

      if (!this.$cookie.get('channelInfo')) {
        let channel = 'not-specified';
        if (typeof this.$route.query !== 'undefined' && typeof this.$route.query.utm_source !== 'undefined') {
          channel = this.$route.query.utm_source;
        }
        if (channel !== '') {
          this.$cookie.set('channelInfo', JSON.stringify({
            channel,
            first_visit: this.$moment().format()
          }), {expires: '3Y'});
        }
      }
    }
  },
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.localeProperties.code.substring(0, 2)
      },
      title: this.$store.state.seo.title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$store.state.seo.description
        },
        {
          hid: 'robots',
          name: 'robots',
          content: this.$store.state.seo.description
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.$store.state.seo.keywords
        },
        {
          hid: 'image',
          name: 'image',
          content: this.$store.state.seo.image
        },
        {
          hid: 'og:image',
          name: 'og:image',
          content: this.$store.state.seo.image
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.$store.state.seo.title
        },
        {
          hid: 'og:type',
          name: 'og:type',
          content: 'website'
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.$store.state.seo.description
        },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1.0, maximum-scale=5.0'
        },
        {
          name: 'theme-color',
          content: '#007dfb'
        },
        {
          name: 'robots',
          content: this.$store.state.seo.robots ? this.$store.state.seo.robots : 'index, follow'
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: this.$utils.baseDomain() + this.$store.state.seo.canonical
        },
        {
          rel: 'preconnect',
          href: 'https://cdn.troubleflight.com/',
          crossorigin: true
        },
        {
          rel: 'dns-prefetch',
          href: 'https://cdn.troubleflight.com/'
        }
      ]
    };
  },
  methods: {
    setDefault() {
      let i18nCopy = Object.assign({}, this.$i18n.localeProperties);

      // Set i18n for moment
      this.$moment.locale(this.$i18n.locale);

      // Set default data for all request thought API
      axios.interceptors.request.handlers = [];
      axios.interceptors.request.use((config) => {
        if (typeof config.params === 'undefined' || typeof config.params !== 'object') {
          config.params = {};
        }
        config.params['language'] = i18nCopy.code.substr(0, 2);
        config.params['market'] = i18nCopy.market;
        config.params['security'] = this.isFakeBrowser;
        config.params['currency'] = i18nCopy.currency;

        return config;
      });
    }
  },
  computed: {
    isFakeBrowser() {
      if (typeof window === 'undefined') return false;
      return (window.outerWidth === 0 && window.outerHeight === 0) ||
          !!window.callPhantom ||
          !!window._phantom ||
          !!window.__phantomas ||
          !!window.Buffer ||
          !!window.emit ||
          !!window.spawn;
    }
  }
};
