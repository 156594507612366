import axios from "axios";

const instance = axios.create({
    baseURL:
        (process.env.NODE_ENV === "production"
            ? "https://a.troubleflight.com"
            : "https://dev-a.ninjatrip.net") + "/api/v3/",
});

export default instance;
