import Vue from 'vue';

const cache = {}; // in-memory cache

const fn = {};

fn.all = async (language) => {
    const now = Date.now();
    const entry = cache[language];

    // Use cached version if within 10 minutes
    if (entry && (now - entry.timestamp < 10 * 60 * 1000)) {
        return entry.data;
    }

    try {
        const res = await fetch(
            process.env.apiEndpoint + 'content/all/trouble-flight/' +
            language.substr(0, 2) + '/' + language.substr(3, 2)
        );

        const json = await res.json();

        cache[language] = {
            timestamp: now,
            data: json.response
        };

        return json.response;
    } catch (err) {
        console.error(`[cachePages] Failed to fetch for ${language}`, err);
        return []; // Return empty to avoid crashing
    }
};

fn.get = async (code, language) => {
    const pages = await fn.all(language);
    return pages.find(page => page.code === code);
};

fn.getSlug = async (code, language) => {
    const pages = await fn.all(language);
    return pages.find(page => {
        return page.i18n && Object.values(page.i18n.slugs).includes(code);
    });
};

fn.slug = async (slug, language) => {
    const pages = await fn.all(language);
    return pages.find(page => page.slug === slug);
};

Vue.prototype.$cachePages = fn;
