import Vue from 'vue';

export default ({ app } , inject) => {
    inject('standardErrors', (err) => {
        console.log('standardErrors', err, JSON.stringify(err));
        // http code error
        console.log('standardErrors', err.response);

        if (!err.response) {
            return {
                message: app.i18n.t('standardErrors.NetworkError'),
                type: 'error',
                timeout: 5000,
                visible: true
            };
        } else {
            console.log('standardErrors', 'unknown error');
        }
    })
};